<template>
    <div class="video__container">
        
        <!-- <video-embed
      @play="test"
      css="embed-responsive-16by9"
      :src="slice.primary.video.embed_url"
      class="player"
      id="play"
    ></video-embed> -->
        <vimeo-player v-if="loading" :controls="contr" ref="player" :video-id="id" class="embed-responsive"
             :options="options"></vimeo-player>
    </div>
</template>

<script>
    export default {
    name: "WorkVideo",
    data() {
        return {
            id: "",
            loading: false,
            height: 200,
            contr: true,
            options: {
                color: "B8FF45",
                transparent: true,
                byline: false,
            }
        };
    },
    props: {
        slice: Object,
    },
    methods: {
        setIframeHeight() {
            // var iframe_cont = document.getElementById("play");
            // var iframe_cont = document.querySelector(".player");
            // console.log(iframe_cont)
            var height_video = (this.slice.primary.vimeo_url.height / this.slice.primary.vimeo_url.width) *
                100;
            // iframe_cont.style.paddingBottom = height_video + "%";
            document.documentElement.style.setProperty("--videoRatio", height_video + "%");
        },
        play() {
            this.$store.commit("play");
        },
        pause() {
            this.$store.commit("pause");
        }
    },
    mounted() {
        // console.log(this.slice);
        const regex = /vimeo\.com\/(\d+)/;
        const video = this.slice.primary.vimeo_url.embed_url.match(regex);
        console.log(video)
        this.id = video[1];
        this.loading = true;
        this.setIframeHeight();
        // console.log(this.id)
    },
};
</script>
<style lang="scss">
    :root {
        --videoRatio: 56.25%;
    }

    // #player {
    //   margin: auto;
    //   width: 50%;
    // }
    // .player{
    //   padding: 0 30%;
    //   margin: auto;
    //   border: 1px solid red;
    // }
    // iframe{
    //   border: 1px solid red;
    // }

    // .html5-video-player{
    //   border: 1px solid red;
    // }

    // .embed-responsive-item{
    //   border-radius: 20px;
    //   width: 100%;
    //   height: 400px;
    //   border: 1px solid red;
    // }

    .video__container {

        
        width: clamp(150px, 100%, 1080px);
        @media screen and (max-width:768px) {
            // width: 90%;
        }

        margin: auto;
        margin-top: 150px;
        // padding-bottom: max(var(--videoRatio), 607.5px);
        // padding-bottom: max(56.25%, 607.5px);
        // background: green;
        // padding-bottom: 56.25% ;
    }

    .embed-responsive {
        position: relative;
        margin: auto;
        padding-bottom: var(--videoRatio);
        // padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }

    iframe {
        background: black;
        // opacity: 0.2;
        border: none;
        // border: 1px solid red;
        // border-radius: 12px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>